import {
    Box,
    Card,
    CardContent,
    LinearProgress,
    Stack,
    Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../app/store";
import { Link } from "../../components/Link";
import { Page } from "../../components/Page";
import { conditionToEmoji } from "../../utils/conditionToEmoji";
import { setDocumentTitle } from "../../utils/setDocumentTitle";
import { setTitle } from "../header/headerSlice";
import {
    useDeleteCardFromCollectionMutation,
    useGetCollectionQuery,
} from "./collectionsApi";

export const CollectionPage: FC = () => {
    const dispatch = useAppDispatch();
    const params = useParams<{ collectionId: string }>();
    const collectionId = parseInt(params.collectionId ?? "", 10);
    const { t } = useTranslation(["cardVariant", "collectionPage"]);

    const [deleteCardFromCollection] = useDeleteCardFromCollectionMutation();
    const { data: collection } = useGetCollectionQuery(collectionId, {
        skip: !collectionId,
    });

    useEffect(() => {
        dispatch(setTitle(collection?.collectionName ?? ""));
        setDocumentTitle(collection?.collectionName ?? "");
    }, [collection?.collectionName, dispatch]);

    return (
        <Page>
            <Stack spacing={1}>
                {(collection?.cards ?? []).map((card) => (
                    <Link
                        key={`${card.cardId}-${card.cardVariant}-${card.condition}`}
                        href={`/card/${card.cardId}/${encodeURIComponent(
                            card.cardName
                        )}`}
                        sx={{ textDecoration: "none" }}
                    >
                        <Card
                            key={`${card.cardId}-${card.cardVariant}-${card.condition}`}
                            sx={{ flexGrow: 1 }}
                        >
                            <CardContent sx={{ p: "16px !important" }}>
                                <LinearProgress
                                    value={
                                        (100 * Math.min(card.numInInventory, card.quantity)) /
                                        card.quantity
                                    }
                                    variant="determinate"
                                    sx={{ mb: 1 }}
                                />
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                    >
                                        <img
                                            alt={card.cardName}
                                            src={card.expansionSymbol}
                                            style={{
                                                height: 24,
                                                marginRight: 6,
                                            }}
                                        />
                                        <Typography>
                                            {card.cardName} {card.cardNumber}/
                                            {card.printedTotal}
                                        </Typography>
                                    </Box>
                                    <Typography>
                                        {card.numInInventory}/{card.quantity}
                                    </Typography>
                                </Box>
                                <Box>
                                    {t(`cardVariant:${card.cardVariant}`)}{" "}
                                    {conditionToEmoji(card.condition)}
                                </Box>
                            </CardContent>
                        </Card>
                    </Link>
                ))}
            </Stack>
        </Page>
    );
};
