import {
    Autocomplete,
    Box,
    FormControl,
    Pagination,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../app/store";
import { DebouncedInput } from "../../components/DebouncedInput";
import { Page } from "../../components/Page";
import { useGetCardsQuery } from "../../features/card/cardApi";
import { setTitle } from "../../features/header/headerSlice";
import { setDocumentTitle } from "../../utils/setDocumentTitle";
import { CardsTable } from "./CardsTable";

export const BrowseCardsPage: FC = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation("browseCardsPage");
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));

    const [page, setPage] = useState(parseInt(searchParams.get("page") ?? "", 10) || 1);
    const [selectedExpansions, setSelectedExpansion] = useState(searchParams.get("expansionIds") ?? undefined);
    const [searchInput, setSearchInput] = useState(searchParams.get("q") ?? undefined);

    const { data: cardPage } = useGetCardsQuery({
        expansionIds: selectedExpansions?.split(",") ?? [],
        page,
        q: searchInput,
    });

    useEffect(() => {
        dispatch(setTitle(t("title")));
        setDocumentTitle(t("title"));
    }, [dispatch, t]);

    // Update search params
    useEffect(() => {
        const searchParams = new URLSearchParams();
        if (selectedExpansions) {
            searchParams.append("expansionIds", selectedExpansions);
        }
        searchParams.append("page", (page ?? 1).toString());
        if (searchInput) {
            searchParams.append("q", searchInput);
        }
        setSearchParams(searchParams, { replace: true });
        // setSearchParams isn't stable, so let's remove it from the dependency list until it is
        // https://github.com/remix-run/react-router/issues/9991
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchInput, selectedExpansions]);

    // Update state from search params
    useEffect(() => {
        setPage(parseInt(searchParams.get("page") ?? "", 10) || 1);
        setSelectedExpansion(searchParams.get("expansionIds") ?? undefined);
        setSearchInput(searchParams.get("q") ?? undefined);
    }, [searchParams]);

    const numPerPage = cardPage?.numPerPage ?? 0;
    const numTotalCards = cardPage?.numTotalCards ?? 0;
    const from = Math.min(1 + ((page ?? 1) - 1) * numPerPage, numTotalCards);
    const to = Math.min((page ?? 1) * numPerPage, numTotalCards);

    return (
        <Page>
            <Stack alignItems="center" direction={{ xs: "column", md: "row" }}>
                <FormControl
                    fullWidth={!desktop}
                    sx={{ m: 1, width: desktop ? 400 : undefined }}
                >
                    <Autocomplete
                        disableCloseOnSelect
                        disableListWrap
                        disablePortal
                        getOptionLabel={(option) => option.name}
                        limitTags={1}
                        multiple
                        onChange={(_, expansions) => {
                            setSelectedExpansion(
                                expansions.map((e) => e.expansionId).join(",")
                            );
                            setPage(1);
                        }}
                        options={cardPage?.filterData?.expansions ?? []}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("filters.expansions")}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props} key={option.expansionId}>
                                {option.name}
                            </li>
                        )}
                        value={(cardPage?.filterData?.expansions ?? []).filter(
                            (e) =>
                                selectedExpansions
                                    ?.split(",")
                                    .includes(e.expansionId)
                        )}
                    />
                </FormControl>
                <FormControl fullWidth={!desktop} sx={{ m: 1 }}>
                    <DebouncedInput
                        label={t("filters.searchInput")}
                        onChange={setSearchInput}
                        placeholder={t("filters.searchInputPlaceholder")}
                        value={searchInput ?? ""}
                    />
                </FormControl>
            </Stack>
            <CardsTable
                cards={cardPage?.cards ?? []}
                from={from}
                numTotalCards={cardPage?.numTotalCards ?? 0}
                to={to}
            />
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                my={3}
            >
                <Pagination
                    count={Math.ceil(numTotalCards / numPerPage) || 1}
                    onChange={(_, value) => setPage(value)}
                    page={page ?? 1}
                />
            </Box>
        </Page>
    );
};
